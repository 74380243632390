<style lang="scss">
@import "~@/assets/css/var";

.drug-product-category {
    font-size: 12px;

    .span_margin {
        margin-left: 5px;
    }
}
</style>
<template>
    <div class="common-table search-result">
        <div class="common-table__title">
            {{ $route.params.txt }}
            <el-tag style="margin-left: 10px;" v-show="isAccurate" type="success" size="small"
                disable-transitions>精确</el-tag>
            <div v-show="isAccurate && category.treatBig && tab === 'product'" class="drug-product-category">
                <template v-if="category.treatBig">
                    <span class="span_margin">大类：</span>
                    <el-tag type="success" size="small">{{ category.treatBig }}</el-tag>
                </template>
                <template v-if="category.treatSmall">
                    <span class="span_margin">亚类：</span>
                    <el-tag type="success" size="small">{{ category.treatSmall }}</el-tag>
                </template>
                <template v-if="category.treatThree">
                    <span class="span_margin">三类：</span>
                    <el-tag type="success" size="small">{{ category.treatThree }}</el-tag>
                </template>
                <template v-if="category.treatFour">
                    <span class="span_margin">四类：</span>
                    <el-tag type="success" size="small">{{ category.treatFour }}</el-tag>
                </template>
            </div>
            <el-button v-show="$help.token.get()" type="primary" style="margin-left: 10px;" round
                @click="goMyStar">我的关注和评论</el-button>
            <div class="flex-item"></div>
            <ts-search :param="express"></ts-search>
        </div>

        <div v-if="isEmpty" class="search-result__container" style="margin-top: 20px;padding-top: 100px;">
            <ts-empty></ts-empty>
        </div>
        <div v-else class="search-result-body">
            <el-tabs v-model="tab" type="border-card">
                <el-tab-pane name="product"><span slot="label">{{ pageMap.product }}</span></el-tab-pane>
                <el-tab-pane name="bid"><span slot="label">{{ pageMap.bid }}</span></el-tab-pane>
                <el-tab-pane name="sale"><span slot="label">{{ pageMap.sale }}</span></el-tab-pane>
                <el-tab-pane name="dev"><span slot="label">{{ pageMap.dev }}</span></el-tab-pane>
                <el-tab-pane name="one"><span slot="label">{{ pageMap.one }}</span></el-tab-pane>
                <el-tab-pane name="explain"><span slot="label">{{ pageMap.explain }}</span></el-tab-pane>
                <el-tab-pane name="point"><span slot="label">{{ pageMap.point }}</span></el-tab-pane>
                <el-tab-pane name="path"><span slot="label">{{ pageMap.path }}</span></el-tab-pane>
                <el-tab-pane name="doc"><span slot="label">{{ pageMap.doc }}</span></el-tab-pane>
                <el-tab-pane name="news"><span slot="label">{{ pageMap.news }}</span></el-tab-pane>
            </el-tabs>
        </div>

        <div class="search-result__container" v-if="loaded && !isEmpty">
            <div class="search-result__panel" :class="{ show: tab === 'product' }">
                <template v-if="finished.product">
                    <drug-product-info :base-query="baseQuery" :visit-id="visitId" :is-accurate="isAccurate"
                        @setCategory="setCategory"></drug-product-info>
                </template>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'bid' }">
                <drug-bid-info v-if="finished.bid" :base-query="baseQuery" :visit-id="visitId"></drug-bid-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'sale' }">
                <drug-sale-info v-if="finished.sale" :isAccurate="isAccurate" :visit-id="visitId"
                    :base-query="baseQuery"></drug-sale-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'dev' }">
                <drug-dev-info v-if="finished.dev" :base-query="baseQuery" :visit-id="visitId"></drug-dev-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'one' }">
                <drug-uniformity-info v-if="finished.one" :base-query="baseQuery"
                    :visit-id="visitId"></drug-uniformity-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'explain' }">
                <drug-instruction-info v-if="finished.explain" :base-query="baseQuery"
                    :visit-id="visitId"></drug-instruction-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'point' }">
                <drug-point-info v-if="finished.point" :base-query="basePoint" :visit-id="visitId"></drug-point-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'path' }">
                <drug-path-info v-if="finished.path" :base-query="basePoint" :visit-id="visitId"></drug-path-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'doc' }">
                <drug-doc-info v-if="finished.doc" :base-query="basePoint" :visit-id="visitId"></drug-doc-info>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'news' }">
                <drug-news-info v-if="finished.news" :base-query="basePoint" :visit-id="visitId"></drug-news-info>
            </div>
        </div>
    </div>
</template>

<script>
import DrugProductInfo from './DrugProductInfo'
import DrugBidInfo from './DrugBidInfo'
import DrugSaleInfo from './DrugSaleInfo'
import DrugDevInfo from './DrugDevInfo'
import DrugUniformityInfo from './DrugUniformityInfo'
import DrugInstructionInfo from './DrugInstructionInfo'
import DrugPointInfo from './DrugPointInfo'
import DrugPathInfo from './DrugPathInfo'
import DrugDocInfo from './DrugDocInfo'
import DrugNewsInfo from './DrugNewsInfo'
export default {
    components: { DrugProductInfo, DrugBidInfo, DrugSaleInfo, DrugDevInfo, DrugUniformityInfo, DrugInstructionInfo, DrugPointInfo, DrugPathInfo, DrugDocInfo, DrugNewsInfo },
    data() {
        return {
            visitId: 0,
            pageId: '',
            pageName: '',
            pageTitleSelf: true,

            pageMap: {
                product: '产品信息',
                bid: '招投标',
                sale: '产品销售',
                dev: '产品研发',
                one: '一致性评价',
                explain: '药品说明书',
                point: '临床用药指南',
                path: '临床路径',
                doc: '产品文献',
                news: '产品新闻',
            },

            isAccurate: false,
            tab: 'product',
            loaded: false,
            express: {
                txt: '',
                type: '',
            },
            baseQuery: {},
            basePoint: {},
            finished: {
                product: false,
                bid: false,
                sale: false,
                dev: false,
                one: false,
                explain: false,
                point: false,
                path: false,
                doc: false,
                news: false,
            },
            isEmpty: false,
            category: {},
        }
    },
    created() {
        this.init();
        this.$help.setTitle('药品搜索-' + this.$route.params.txt)
    },
    mounted() {
        this.$nextTick(() => {
            this.loaded = true
        })
    },
    watch: {
        '$route': function () {
            this.init();
            this.loaded = false
            this.$nextTick(() => {
                this.loaded = true
            })
        },
        tab: {
            handler(val) {
                this.finished[val] = true

                this.visitId = new Date().getTime()
                this.pageId = val
                this.pageName = '药品搜索-' + this.pageMap[val]

                this.$help.socket.send(this)
            },
            immediate: true
        },
    },
    methods: {
        setCategory(val) {
            this.category = val
        },
        init() {
            this.isAccurate = !!this.$route.query.accurate
            this.isEmpty = false
            const txt = this.$route.params.txt
            // 如果没有内容，跳转回首页
            if (txt) {
                this.express.txt = txt
                this.express.type = this.$variable.indexType.DRUG
                this.baseQuery = {
                    comName: this.isAccurate ? txt : `%${txt}%`

                }
                this.basePoint = {
                    products: `%${txt}%`
                }
            }
        },
        goMyStar() {
            const { href } = this.$router.resolve({
                path: `/star`,
            })
            window.open(href, "_blank")
        },
    },
}
</script>